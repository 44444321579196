.mega-menu-navigation-item_link {
  position: relative;
}

.mega-menu-navigation-item--expanded
  .mega-menu-navigation-item__link_after::after {
  position: absolute;
  top: calc(100% + 4px);
  left: 50%;
  content: '';
  border-right: 12px solid transparent;
  border-bottom: 12px solid var(--color-surface-bg);
  border-left: 12px solid transparent;
  transform: translateX(-50%);
}

.fade-out {
  transition: opacity 0.6s ease-in-out;
}

.fade-out.hidden {
  opacity: 0;
}

.fade-in {
  display: none;
  opacity: 0;
  animation: fade-in-out 0.3s ease-in-out;
}

.fade-in.visible {
  display: block;
  opacity: 1;
}

@keyframes fade-in-out {
  0% {
    display: none;
    opacity: 0;
  }

  50% {
    display: block;
    opacity: 0.5;
  }

  100% {
    display: block;
    opacity: 1;
  }
}
